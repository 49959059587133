






/* eslint-disable */
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'my-refresh',
  setup(_props, ctx) {
    if (location.href.indexOf('reload') == -1) {
      location.href = location.href + '?reload'
    }
    ctx.root.$router.push({ name: 'MyRecord' })
    return {
    }
  },
})
